<template>
  <div class="flex items-center justify-center space-x-3 mx-6 my-4">
    <div
      v-for="i in 3"
      :key="i"
      class="w-4 h-4 mx-1 rounded-full animate-ping bg-violet-400"
      :style="`animation-delay: ${i * 150}ms`"
    ></div>
  </div>
</template>
